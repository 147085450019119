<template>
	<div class="purchase-frame">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="预定内容：">
				<span class="h-color">{{yd_content}}</span>
			</el-form-item>
			<el-form-item label="姓名：" prop="name">
				<el-input type="text" v-model="ruleForm.name" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="电话：" prop="phone">
				<el-input type="text" v-model="ruleForm.phone" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="婚期：" prop="hunqi">
				<el-date-picker v-model="ruleForm.hunqi" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="请选择"
				:picker-options="yearoptions" @change="hunqichange">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="典礼时间：" prop="shijian">
				<el-select v-model="ruleForm.shijian" placeholder="请选择">
					<el-option v-for="item in timeArr" :key="item.id" :label="item.name" :value="item.id">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="酒店名称：" prop="hotel">
				<el-input type="text" v-model="ruleForm.hotel" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="客服：">
				<el-select v-model="ruleForm.salesman" placeholder="请选择">
					<el-option v-for="item in SalesmanList" :key="item.id" :label="item.name" :value="item.name">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="举办城市：" prop="selectedcity" class="all-city">
				<el-select v-model="ruleForm.selectedcity.province" placeholder="选择省" @change="shengchange" class="city-box">
					<el-option v-for="item in ProvinceList" :key="item.id" :label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="ruleForm.selectedcity.city" placeholder="选择市" @change="shichange" class="city-box">
					<el-option v-for="item in CityList" :key="item.id" :label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
				<el-select v-model="ruleForm.selectedcity.area" placeholder="选择区" class="city-box">
					<el-option v-for="item in AreaList" :key="item.id" :label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
			</el-form-item>

			<el-form-item label="备注内容：">
				<el-input type="textarea" autosize v-model="ruleForm.contents" placeholder="请输入"></el-input>
			</el-form-item>
		</el-form>
		<div class="frame-footer">
			<div class="fra-foot-item" v-if="show_dj">
				<span v-if="shenfen == '公司'">折扣价格：</span>
        <span v-else>预付定金：</span>
				<span class="y-color">￥{{front_money}}</span>
			</div>
			<div class="fra-foot-item" v-if="travel_show">
				<span>差旅费：</span>
				<span class="y-color">￥{{travel_expenses}}</span>
			</div>
			<div class="fra-button" v-if="shenfen == '个人'&&have_num==true" @click="openpay('ruleForm')">支付定金</div>
			<div class="fra-button" v-if="shenfen == '公司'&&have_num==true" @click="openpay('ruleForm')">提交订单</div>
      <span v-if="have_num==false" class="fra-notice" @click="openKF">如需加单，联系客服</span>
      <div class="fra-button" v-if="have_num==false" style="opacity: 0.5;">今日已订满</div>
		</div>


	</div>
</template>

<script>
	import {
		getshenglist,
		getshilist,
		getqulist,
		getywyList,
	} from "@/api/api.js"
	import{
		addorderList,
    getdqnum,
    getvideodqnum,
    getdjmoney
	}from "@/api/joggle.js"
	export default {
		props:['id','yd_content','czname'],
		components:{
		},
		data() {
			var validatearea = (rule, value, callback) => {
				if (!value.province && !value.city && !value.area) {
					return callback(new Error('请选择地区！'));
				}else if (!value.province) {
					return callback(new Error('请选择省！'));
				}else if(!value.city) {
					return callback(new Error('请选择市！'));
				}else if(!value.area) {
					return callback(new Error('请选择区！'));
				} else {
					return callback();
				}
			};
			return {
				shenfen: '个人',
				ruleForm: {
					name: '',
					phone: '',
					hunqi: '',
					shijian: '',
					selectedcity:{
						province:'',
						city:'',
						area:'',
					},
					hotel: '',
					salesman: '',
					contents: ''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入姓名！',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入电话！',
						trigger: 'blur'
					},
					{
						pattern: /^1[3-9]\d{9}$/,
						message: '手机号格式不正确',
						trigger: 'blur'
					}],
					hunqi: [{
						required: true,
						message: '请选择婚期！',
						trigger: 'change'
					}],
					selectedcity:[{
						validator: validatearea,
						trigger: 'change',
						required: true
					}],
					shijian: [{
						required: true,
						message: '请选择典礼时间！',
						trigger: 'change'
					}],

					hotel: [{
						required: true,
						message: '请输入酒店名称！',
						trigger: 'blur'
					}],

				},
				ProvinceList:[],// 省列表
				CityList:[],// 市列表
				AreaList:[], // 区列表
				SalesmanList: [], // 业务员列表
				timeArr: [{
						id: 1,
						name: '上午'
					},
					{
						id: 2,
						name: '下午'
					}
				],
				yearoptions:{
					disabledDate(time){
						return time.getTime() < new Date();
					}
				},
				travel_expenses:'', // 差旅费
				travel_show:false, // 是否显示差旅费
				front_money:0, //定金
				success_info:{}, //个人预定成功后返回数据
        have_num:true,
        show_dj:false,
			}
		},
		created() {
			var that = this
			that.getprovincesList(); // 获取省份列表
			that.getsalesmanList(); // 获取业务员列表
			// 获取定金
      that.getDingjin(that.id);
			// var company_info = JSON.parse(localStorage.getItem('company_info'));
			// if(company_info){
			// 	that.front_money = company_info.deposit;
			// }
			// 获取登录人身份
			var user_info = JSON.parse(localStorage.getItem('user_info'));
			if(user_info){
				if(user_info.level == 1){
					that.shenfen = '公司'
				}
				if(user_info.level == 2){
					that.shenfen = '个人'
				}
			}
		},
		methods: {
      getDingjin(id){
        var that = this
        var obj = {
          cid:id
        }
        getdjmoney(obj).then(res => {
        	if(res.code == 1){
            that.front_money = res.data;
            that.show_dj = true;
        	}
        })
      },
			hunqichange(e){ //查询档期是否已满
        var that = this
        if(that.czname=='婚礼摄影'){
          var obj = {
            id:that.id,
            date:e
          }
          getdqnum(obj).then(res => {
          	if(res.code == 1){
              that.have_num = true;
          	}else{//已订满
              that.have_num = false;
            }
          })
        }
        if(that.czname=='婚礼摄像'){
          var obj = {
            id:that.id,
            date:e
          }
          getvideodqnum(obj).then(res => {
          	if(res.code == 1){
              that.have_num = true;
          	}else{//已订满
              that.have_num = false;
            }
          })
        }
      },
			// 业务员列表
			getsalesmanList() {
				var that = this
				var obj = {}
				getywyList(obj).then(res => {
					if(res.code == 1){
						that.SalesmanList = res.data;
					}
				})
			},
			// 获取省列表
			getprovincesList() {
				var that = this
				var obj = {}
				getshenglist(obj).then(res => {
					if(res.code == 1){
						that.ProvinceList = res.data;
					}
				})
			},
			// 省份变化时
			shengchange(e) {
				var that = this
				// 省发生变化时,对应的市和区都发生变化,故清空市  区/县的选择
				that.ruleForm.selectedcity.city = '';
				that.ruleForm.selectedcity.area = '';
				that.ruleForm.selectedcity.province = e;
				if (e) {
					// 根据所选择的省份获取对应的市
					that.getcityList(e);
				}
			},
			// 获取市列表
			getcityList(e) {
				var that = this
				var obj = {
					id: e
				}
				getshilist(obj).then(res => {
					if(res.code == 1){
						that.CityList = res.data;
					}
				})
			},
			shichange(e){
				var that = this
				// 市发生变化,则相应的区也变化,故清空区的选择
				that.ruleForm.selectedcity.area = '';
				that.ruleForm.selectedcity.city = e;
				if (e) {
					// 根据所选择的市获取对应的区/县
					that.getareaList(e);
					that.CityList.forEach((item,index) => {
						if(item.id == e){
							that.travel_expenses = item.price; //差旅费
							if(Number(that.travel_expenses) > 0){
								that.travel_show = true;
							}else{
								that.travel_show = false;
							}
						}
					})
				}
			},
			// 获取区/县列表
			getareaList(e){
				var that = this
				var obj = {
					id: e
				}
				getqulist(obj).then(res => {
					if(res.code == 1){
						that.AreaList = res.data;
					}
				})
			},
      openKF(){
        let that = this
        that.$emit('openkf', true);
      },
			// 点击下单
			openpay(formName) {
				var that = this
				that.$refs[formName].validate((valid) => {
					if (valid) {
						var obj = {
							cid:that.id,
							name:that.ruleForm.name,
							phone:that.ruleForm.phone,
							day:that.ruleForm.hunqi,
							time:that.ruleForm.shijian,
							province:that.ruleForm.selectedcity.province,
							city:that.ruleForm.selectedcity.city,
							area:that.ruleForm.selectedcity.area,
							hotel:that.ruleForm.hotel,
							service:that.ruleForm.salesman,
							desc:that.ruleForm.contents
						}
						addorderList(obj).then(res => {
							if(res.code == 1){
								// if(that.shenfen == '个人'){
								// 	that.success_info = res.data;
								// 	that.$message.success({
								// 		message:'预定成功，请在15分钟内支付订单！',
								// 		duration:3000,
								// 		customClass: 'messageIndex'
								// 	});
								// }else{
								// 	that.$message.success({
								// 		message:'预定成功！',
								// 		customClass: 'messageIndex'
								// 	});
								// }
								that.$message.success({
									message:'预定成功！',
									customClass: 'messageIndex'
								});

								// 清空值
								that.$refs[formName].resetFields(); //改为初始值
								that.ruleForm.selectedcity.province = '';
								that.ruleForm.selectedcity.city = '';
								that.ruleForm.selectedcity.area = '';
								that.ruleForm.hotel = '';
								that.ruleForm.salesman = '';
								that.ruleForm.contents = '';
								// 关闭弹框
								that.$emit('newval', false,that.shenfen,that.success_info);

							}else{
								that.$message.error(res.msg);
							}
						})
					} else {
						return false;
					}
				});
			},

			// resetForm(formName) {
			// 	this.$refs[formName].resetFields();
			// },
		}
	}
</script>

<style lang="scss">
	.messageIndex {
	  z-index: 9999 !important; // 设置的值一定要比2007大，因为dialog的层级是2007
	}
	.purchase-frame {
		width: 100%;

		.all-city{
			.el-form-item__content{
				display: flex;
				align-items: center;
			}
			.city-box:nth-child(2){
				margin: 0 1.5%;
			}
			.city-box{
				width: 33%;

				.el-input__inner{
					width: inherit;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}

		.frame-footer {
			padding: 20px 0 0 0;
			border-top: 1px solid #F5F5F5;
			display: flex;
			align-items: center;
      .fra-notice{
        color: #F56C6C;
      }
			.fra-button {
				width: 148px;
				height: 40px;
				background: #DCB16B;
				border-radius: 4px;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				line-height: 40px;
				margin-left: auto;
				letter-spacing: 3px;
			}

			.fra-foot-item {
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				margin-right: 20px;

				.y-color {
					font-size: 18px;
					font-weight: bold;
					color: #DCB16B;
				}
			}

		}

		.h-color {
			font-size: 16px;
			font-weight: 600;
			color: #333333;
			letter-spacing: 3px;
		}

		.el-form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		.el-form-item {
			width: 45%;
			display: inline-block;
			margin-bottom: 28px;
		}

		.el-select,
		.el-cascader,
		.el-input {
			width: 100%;

			.el-input__inner {
				border: none;
				background: #F5F5F5;
				border-radius: 4px;
				font-size: 14px;
				font-weight: 400;
				// color: #C0C0C0;
				color: #333333;
				letter-spacing: 3px;
				height: 40px;
			}
		}

		.el-input__prefix {
			right: 5px;
			left: auto;
		}

		.el-form-item__label {
			padding: 0 10px 0 0;
		}

		.el-form-item:first-child,
		.el-form-item:last-child,
		.el-form-item:nth-last-child(2){
			width: 100%;
		}

		.el-date-editor {
			.el-input__inner {
				padding-left: 15px;
			}
		}

		.el-textarea {
			.el-textarea__inner {
				min-height: 40px !important;
				line-height: 1.9;
				background: #F5F5F5;
				border-radius: 4px;
				font-size: 14px;
				font-weight: 400;
				// color: #C0C0C0;
				color: #333333;
				letter-spacing: 3px;
				border: none;
			}

			.el-textarea__inner {
				resize: none;
			}
		}
	}
</style>
